import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Header from './Home/Header'
import { createContext } from 'react'
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import Home from './Home/Home'
import LOBenefits from './Home/LOBenefits'
import FoundersMessage from './Home/FoundersMessage'
import Licensing from './Home/Licensing'

export const UserAuthContext = createContext(null)

function App() {

  return (
    <div className="App">
        <Router>
          <Header/>
          <Switch>
          <Route exact path="/LObenefits" component={LOBenefits} />
          <Route exact path="/founderMessage" component={FoundersMessage} />
          <Route exact path="/licensing" component={Licensing} />
          <Route exact path="/" component={Home} />
          </Switch>
        </Router>
    </div>
  )
}

export default App
