import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'

export default function Home() {
  return (
    <div className='homePage'>
      <Image src="/dotsleft.png" alt="dots" className="leftDots d-none-xs" />
      <Image src="/dotsright.png" alt="dots2" className="rightDots d-none-xs" />
      <div className="headers">
        <h3>Check out why people are choosing Hypotec <br /> to take their career to the
          next level.</h3>
        <h4>Support, benefits, resources and more. </h4>
      </div>
      <div style={{ textAlign: 'center' }}>
        <video
          controls
          poster="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/mediaAssets%2FHypotecVideo.png?alt=media&token=20b09208-556c-4819-bd98-0160b0467de9"
          width="600"
        >
          <source
            src="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/media%2FWhy%20Hypotec%20-%20Loan%20Officer%20Recruitment%20-%20Final%20Shorter.mp4?alt=media&token=b3250d00-4f63-428d-8f0b-c9309827ca06"
            type="video/mp4"
          />
        </video>
      </div>
      <div className="hrForm">
        <h5 style={{ padding: '25px', textAlign: 'center' }}>
          Create a rewarding career at Hypotec
        </h5>
        <h5 style={{ padding: '25px', textAlign: 'center' }}>
          Get started today by scheduling a call with our HR team!
        </h5>
        <div style={{ textAlign: 'center' }}>
          <Button
            href="https://calendly.com/hypotecmortgages/15min"
            className="Btn"
            target="_blank"
          >
            Schedule a Call
          </Button>
        </div>
      </div>
    </div>
  )
}
