import Navbar from 'react-bootstrap/NavBar'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

export default function Header() {
  let history = useHistory()
  const LObenefitsClick = () => history.push('/LObenefits')
  const founderMessageClick = () => history.push('/founderMessage')
  const licensingClick = () => history.push('/licensing')

  const [show, setShow] = useState(false)

  const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    number: '',
    resume: '',
    resumeName: '',
    referer: '',
  }
  const [eachEntry, setEachEntry] = useState(initialState)
  const { firstName, lastName, email, number, resumeName, resume, referer } = eachEntry

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const handleOnChange = (e) => {
    setEachEntry({ ...eachEntry, [e.target.name]: e.target.value })
  }

  const onFileChange = async (e) => {
    // Update the state
    let file = e.target.files[0]
    let filename = '' + file.name
    let fr = new FileReader()
    fr.onloadend = ResumeLoaded
    fr.readAsDataURL(file)
    // console.log(filename)
    setEachEntry({ ...eachEntry, resumeName: filename })
    // console.log(eachEntry.resumeName)
  }

  const ResumeLoaded = (fr) => {
    const resumeBase64 = fr.target.result
    setEachEntry({ ...eachEntry, resume: resumeBase64 })
  }

  const handleResumeSubmit = (e) => {
    e.preventDefault()
    console.log(eachEntry)
    eachEntry.resumeName = document.getElementById('Resume').files[0].name
    fetch('https://hypotecmlo-api.web.app/sendResume', {
      method: 'POST',
      body: JSON.stringify(eachEntry),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === 'success') {
          alert('Message Sent.')
          // setEachEntry({ ...eachEntry, resume: '' }) //reset form

          handleClose()
        } else if (response.status === 'fail') {
          alert('Message failed to send.')
        }
      })
  }
  return (
    <div className="header">
      <Navbar collapseOnSelect bg="light" expand="lg" className="navBar">
        <Link to={`/`}>
          <img
            src="/Hypoteclogo.png"
            alt="Hypotec a new way of lending"
            className="logo"
          />
        </Link>
        <div className="centerHeader">
          <Button onClick={(e) => LObenefitsClick(e)} className="link">
            Loan Officer Benefits
          </Button>

          <Button
            href="https://firebasestorage.googleapis.com/v0/b/hypotec-lo-campus.appspot.com/o/New%20LO%20presentation.pdf?alt=media&token=cbc74435-81a3-4d63-aa48-1e26089a1805"
            target="_blank"
            className="link"
          >
            Presentation
          </Button>

          <Button onClick={(e) => founderMessageClick(e)} className="link">
            Founder's Message
          </Button>
          <Button onClick={(e) => licensingClick(e)} className="link">
            Get Licensed
          </Button>
        </div>
        <div className="navBarBtns">
          <Button onClick={handleShow} className="Btn" target="_blank">
            Join our team
          </Button>
          <Modal show={show} onHide={handleClose} className="resumeModal">
            <Modal.Header
              closeButton
              className="modal-title"
              style={{ backgroundColor: '#0071e3' }}
            >
              <Modal.Title> Application Form </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
              <Form style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Form.Group style={{ padding: '10px' }}>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    onChange={handleOnChange}
                    value={firstName}
                    name="firstName"
                    type="text"
                  />
                </Form.Group>
                <Form.Group style={{ padding: '10px' }}>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    onChange={handleOnChange}
                    value={lastName}
                    name="lastName"
                    type="text"
                  />
                </Form.Group>
                <Form.Group style={{ padding: '10px' }}>
                  <Form.Label>Primary Phone</Form.Label>
                  <Form.Control
                    onChange={handleOnChange}
                    value={number}
                    name="number"
                    type="num"
                  />
                </Form.Group>
                <Form.Group style={{ padding: '10px' }}>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    onChange={handleOnChange}
                    value={email}
                    name="email"
                    type="email"
                  />
                </Form.Group>
                <Form.Group style={{ padding: '10px' }}>
                <Form.Label>Were you referred by a Loan Officer at our company? If so, please provide their name</Form.Label>
                <Form.Control
                  name="referer"
                  type="text"
                  onChange={handleOnChange}
                  value={referer}
                />
              </Form.Group>
                <Form.Group style={{ padding: '10px' }}>
                  <Form.Label>Upload Resume</Form.Label>
                  <Form.Control
                    name="resume"
                    type="file"
                    id="Resume"
                    onChange={onFileChange}
                  />
                </Form.Group>

                <Button
                  style={{ margin: '10px' }}
                  variant="primary"
                  type="submit"
                  onClick={handleResumeSubmit}
                >
                  Submit
                </Button>
              </Form>
            </Modal.Body>
          </Modal>
        </div>
      </Navbar>
    </div>
  )
}
