export default function FoundersMessage() {
  return (
    <div className="foundersMessagePage">
      <div className="founderImg">
        <img src="/FreddyHeadshot.png" alt="founder headshot" />
      </div>
      <div className="message">
        <h4>Freddy Abitbol</h4>
        <h6>Founder</h6>
        <p>
          Where you choose to develop your career matters. When we set out to
          transform the mortgage experience, I personally thought about what it
          would take for me, having been a loan officer, to want to be at
          Hypotec for the long haul. It needed to be a place that I would enjoy
          and thrive working at. These factors are largely what lead to how we
          structured our highly competitive compensation and support
          opportunities.
        </p>
        <p>
          While compensation is important, we know more goes into an enjoyable
          work place. Hypotec is proud to offer a place that empowers loan
          officers to manage their own businesses while receiving top-notch
          technology, training as well as quick support. We’ve created a place
          where entrepreneurial loan officers are free and encouraged to voice
          their opinions and be heard. Hypotec proudly identifies as a place
          where loan officers can confidently plan for their family’s future.
        </p>
      </div>
    </div>
  )
}
