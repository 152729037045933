import Container from 'react-bootstrap/Container'

export default function Licensing() {
  return (
    <div className="licensingPage">
      <div
        className="licensingHeaders"
        style={{ textAlign: 'center', padding: '0 0 50px 350px' }}
      >
        <h2>
          Steps to Becoming a Mortgage Loan
          <span style={{ color: '#009ee5' }}> Originator</span>
        </h2>
        <p>
          Below you will find the step required to successfully apply for a
          Mortgage Loan Originator License. <br /> If you have any questions,
          please contact the Compliance Officer at licensing@hypotec.com.
        </p>
      </div>
      <Container className="licensingContainer">
        <ol>
          <li style={{ fontWeight: 'bold' }}>Create an Account on NMLS</li>
          <ol type="a">
            <li>
              NMLS provides a quick guide to creating an account in NMLS.
              <ol type="i">
                <li>
                  <a href="https://nationwidelicensingsystem.org/Pages/default.aspx">
                    NMLS Website
                  </a>
                </li>
                <li>
                  State Licensing (top row)
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                    />
                  </svg>
                  Quick Guides – Individual (left column)
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                    />
                  </svg>
                  Access, Relationships & Sponsorships
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                    />
                  </svg>
                  <br />
                  click the hyperlink for “Create an Individual Account”
                </li>
              </ol>
            </li>
          </ol>
          <li style={{ fontWeight: 'bold' }}>Education & Testing</li>
          <ol type="a">
            <li>
              PE Courses
              <ol>
                <li type="circle">
                  Prior to Licensing, all individuals must complete 20 hours of
                  PE (Pre-licensure Education) Courses.
                </li>
              </ol>
            </li>
            <li>
              National Test Component (Uniform State Content) of the S.A.F.E MLO
              Test
              <ol>
                <li type="circle">
                  Once you completed the PE Education, you must take and pass
                  the S.A.F.E. MLO Test
                </li>
                <li type="circle">
                  You will be required to pay an invoice for the cost of testing
                  prior to scheduling an appointment for your test.
                </li>
                <li type="circle">
                  NMLS provides a quick guide with steps on how to pay the
                  invoices and schedule your test appointment:
                  <ol type="i">
                    <li>
                      <a href="https://nationwidelicensingsystem.org/Pages/default.aspx">
                        NMLS Website
                      </a>
                    </li>
                    <li>
                      State Licensing (top row)
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-arrow-right"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                        />
                      </svg>
                      Quick Guides – Individual (left column)
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-arrow-right"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                        />
                      </svg>
                      Professional Standards (section)
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-arrow-right"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                        />
                      </svg>
                      <br />
                      Click the hyperlink for “individual Test Enrollment”
                    </li>
                    <li>
                      You can also find more information regarding testing at
                      &nbsp;
                      <a href="https://nationwidelicensingsystem.org/profreq/testing/pages/default.aspx">
                        NMLS Testing Information Page
                      </a>
                    </li>
                    <li>
                      Prior to testing, NMLS recommends using their content
                      outline to study for the test as it outlines all the
                      details included in the test. <br /> The outline can be
                      found here: &nbsp;
                      <a href="https://nmlsportal.csbs.org/csm?id=kb_article_view&sys_kb_id=bb0314f0db3d37008410388d7c9619ea">
                        Content Outline
                      </a>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              State Specific Courses
              <ol>
                <li type="circle">
                  The number of hours (when applicable) of state specific
                  education requirements will be listed in the education section
                  on each state’s <br /> Mortgage Loan Originators Checklist.
                  How to access this checklist will be listed in section 2.
                </li>
              </ol>
            </li>
            <li>Oncourse Education</li>
            <ol>
              <li type="circle">
                You can sign up for these classes through Oncourse Education.
                You can also shop around with other companies.
              </li>
              <li type="circle">
                We currently have discounted pricing with Oncourse Education.
                The pricing list will be attached.
                <ol type="i">
                  <li>
                    If you do not have a copy, please request a copy from
                    licensing@hypotec.com
                  </li>
                </ol>
              </li>
              <li>
                Please contact our Oncourse Education representative to schedule
                and pay for courses:
                <ol type="i">
                  <li>
                    Andrew Bracken: Relationship Manager <br />
                    <a href="mailto:abracken@oncourselearning.com">
                      abracken@oncourselearning.com
                    </a>
                    <br />
                    443-391-5226
                  </li>
                  <li>
                    Kelly Swift: Senior Regional Sales Manager <br />
                    <a href="mailto:kswift@oncourselearning.com">
                      kswift@oncourselearning.com
                    </a>
                    <br />
                    443-391-5220
                  </li>
                </ol>
              </li>
            </ol>
          </ol>
          <li style={{ fontWeight: 'bold' }}>NMLS Checklist</li>
          <ol type="a">
            <li>
              NMLS provides a checklist of instructions and all the documents
              needed to apply for the MLO license at the state level. <br />
              <span style={{ color: 'red' }}>
                Please thoroughly review the checklist and complete all required
                steps. This will ensure your license is approved with no delays.
              </span>
            </li>
            <li>
              Steps to access the checklist:
              <ol type="i">
                <li>
                  <a href="https://nationwidelicensingsystem.org/Pages/default.aspx">
                    NMLS Website
                  </a>
                </li>
                <li>
                  State Licensing (top row)
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                    />
                  </svg>
                  Choose a state (on map)
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                    />
                  </svg>
                  Individual license
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                    />
                  </svg>
                  choose “new application” next to the MLO license section.
                </li>
              </ol>
            </li>
          </ol>
          <li style={{ fontWeight: 'bold' }}>Applying Through NMLS</li>
          <ol type="a">
            <li>
              NMLS provides a quick guide that will walk you through the process
              of applying for an MLO license.
              <ol type="i">
                <li>
                  <a href="https://nationwidelicensingsystem.org/Pages/default.aspx">
                    NMLS Website
                  </a>
                </li>
                <li>
                  State Licensing (top row)
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                    />
                  </svg>
                  Quick Guides – Individual (left column)
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                    />
                  </svg>
                  Form Filing (section)
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                    />
                  </svg>
                  Click hyperlink for “Individual (MU4) Form Filing”.
                </li>
                <ol>
                  <li>
                    All forms under the “Form Filing” section will assist you in
                    completing your filing
                  </li>
                </ol>
                <li style={{ color: 'red' }}>
                  Once you have finished paying for your license, please email
                  licensing@hypotec.com requesting to be sponsored. <br /> This
                  is required for your license to be approved.
                </li>
              </ol>
            </li>
          </ol>
          <li style={{ fontWeight: 'bold' }}>License Items</li>
          <ol type="a">
            <li>
              Once you have submitted your payment and application for a license
              through NMLS, the state representative reviewing your <br />{' '}
              application may add a license item if there are still items that
              need to be completed before the license can be approved.
              <ol type="i">
                <li>
                  You will receive alerts via email.
                  <span style={{ color: 'red' }}>
                    &nbsp;Make sure to keep an eye out for this as they often
                    have deadlines for completion, <br /> or your application
                    will be withdrawn.
                  </span>
                </li>
              </ol>
            </li>
            <li>
              To view your license items in NMLS:
              <ol type="i">
                <li>
                  Login to NMLS
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                    />
                  </svg>
                  Composite View (upper right column)
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                    />
                  </svg>
                  State License/Registration list
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                    />
                  </svg>
                  Click the hyperlink number under <br /> the “License Item”
                  Column” next to the state in question.
                </li>
              </ol>
            </li>
          </ol>
          <li style={{ fontWeight: 'bold' }}>Approval</li>
          <ol type="a">
            <li>
              Once you are approved, you with be able to begin originating loans
              with our company.
            </li>
            <li>
              If your status changes and includes “(Temporary Authority)”, you
              are able to begin originating loans.
            </li>
            <li style={{ color: 'red' }}>
              It is important to make sure all license items are completed in a
              timely manner so that your ability to fund loans is not revoked.
            </li>
          </ol>
        </ol>
      </Container>
    </div>
  )
}
